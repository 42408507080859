import Button from "../../atoms/Button/Button";
import {useContext} from "react";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

const Intro = () => {
  const { state, dispatch } = useContext(AppContext);

  return (<div>
    <h1>Intro</h1>
    <Button onClick={() => {
      dispatch({type: "NEXT"});
    }}>
      Erste Frage
    </Button>
  </div>);
}

export default Intro;