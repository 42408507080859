import styles from "./RestartButton.module.scss";

const RestartButton = ({onClick}) => (
  <button onClick={onClick} className={styles.button}>
    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="30" viewBox="0 0 41 30">
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <path
            d="M20.1468235,0 C19.8183644,0.00462234691 19.5,0.113501034 19.2376138,0.31123598 L10.0916211,7.17529185 C9.70599347,7.46769572 9.47953671,7.92393061 9.47953671,8.40790309 C9.47953671,8.89187556 9.70598471,9.34810607 10.0916211,9.64051432 L19.2376138,16.5045702 C19.565902,16.7519471 19.9790908,16.8586024 20.3859347,16.8007387 C20.7927875,16.742875 21.1600994,16.5254577 21.4063967,16.1964196 C21.6547533,15.8678942 21.761729,15.4539603 21.703877,15.046333 C21.6461958,14.6385436 21.4281345,14.2706535 21.0983214,14.0243066 L15.6522646,9.94882271 L28.7220998,9.94882271 C33.390837,9.94882271 37.1131198,13.7016144 37.1131198,18.4322558 C37.1131198,23.1628972 33.3912314,26.9064855 28.7220998,26.9064855 L1.54045312,26.9064855 C1.13034914,26.9080262 0.737544243,27.0725472 0.448612724,27.3637678 C0.159865239,27.6549708 -0.00152277601,28.0490777 0,28.4592469 C0.00155127306,28.8675491 0.164496731,29.2583734 0.452906823,29.5470126 C0.741483421,29.8356475 1.13242608,29.9984661 1.54045312,30 L28.7220998,30 C35.0650974,30 40.2061856,24.8087893 40.2061856,18.4320805 C40.2061856,12.0553717 35.0655356,6.86722889 28.7220998,6.86722889 L15.6522646,6.86740007 L21.0983214,2.78897985 L21.0983214,2.78880866 C21.4272932,2.54245734 21.6446797,2.17524218 21.7025186,1.76831613 C21.7601998,1.36121039 21.6537368,0.947933917 21.4064098,0.619763502 C21.1606209,0.291238095 20.7945007,0.0739961176 20.3885331,0.0154443448 C20.3084306,0.0041455181 20.2276402,-0.000990471605 20.1468542,0.000379096433 L20.1468235,0 Z"
            className="page-1__02-ingame-screen-copy__noun-back-5153385__path"/>
        </g>
      </g>
    </svg>
  </button>
);

export default RestartButton;