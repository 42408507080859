import Popup from "../../atoms/Popup/Popup.jsx";
import Headline2 from "../../atoms/Headline2/Headline2.jsx";

import Headline3 from "../../atoms/Headline3/Headline3.jsx";
import Paragraph from "../../atoms/Paragraph/Paragraph.jsx";

const ImprintPopup = ({show, onClose}) => (
  <Popup show={show} onClose={onClose}>
    <Headline2>Impressum</Headline2>
    <Paragraph>
      <strong>RMS Radio Marketing Service GmbH & Co. KG</strong><br/>
      Barmbeker Str. 4<br/>
      22303 Hamburg
    </Paragraph>
    <Paragraph>
      T. +49 40 23890-0
    </Paragraph>
    <Paragraph>
      https://rms.de/<br/>
      service@rms.de
    </Paragraph>
    <Paragraph>
      Registergericht Hamburg: HRA 83758<br/>
      Ust-Ident-Nr.: DE118914811
    </Paragraph>
    <Paragraph>
      vertreten durch die persönlich haftende Gesellschafterin:<br/>
      Beteiligungsgesellschaft RMS Radio Marketing Service mbH<br/>
      Barmbeker Str. 4<br/>
      22303 Hamburg
    </Paragraph>
    <Paragraph>
      Registergericht Hamburg: HRB 42373<br/>
      diese vertreten durch die Geschäftsführer Stefan Mölling, Sven Thölen.
    </Paragraph>
    <Headline3>Inhaltlich verantwortlich:</Headline3>
    <Paragraph>
      Diana Bellmann, RMS Radio Marketing Service GmbH & Co. KG, Barmbeker Str. 4, 22303 Hamburg
    </Paragraph>
  </Popup>
);

export default ImprintPopup;