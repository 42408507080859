import {AppContextProvider} from "./utilities/AppContext/AppContext.jsx";

import './main.scss';

import Header from "./components/elements/Header/Header.jsx";
import Main from "./components/elements/Main/Main.jsx";

const App = () => {
  return (
    <AppContextProvider>
      <Main/>
      <Header/>
    </AppContextProvider>
  );
}

export default App;
