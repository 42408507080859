import styles from './Header.module.scss';
import logo from './logo_rms.svg';
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import {useContext} from "react";

const Header = () => {
  const { state, dispatch } = useContext(AppContext);

  return (
    <header className={styles.header}>
      <img src={ logo } alt="logo" />
      <nav className={styles.header__nav}>
        <ul>
          <li><a href="#" onClick={() => { dispatch({type: "TOGGLE_CONDITIONS"})}}>Teilnahmebedingungen</a></li>
          <li><a href="#" onClick={() => { dispatch({type: "TOGGLE_DATAPROTECTION"}) }}>Datenschutz</a></li>
          <li><a href="#" onClick={() => { dispatch({type: "TOGGLE_IMPRINT"})}}>Impressum</a></li>
        </ul>
      </nav>
    </header>
  )
}

export default Header;