import {useContext, useRef, useState} from "react";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import styles from "../Playlists/Playlists.module.scss";
import Headline1 from "../../atoms/Headline1/Headline1.jsx";
import Button from "../../atoms/Button/Button";
import AudioPlayer from 'react-h5-audio-player';
import Playlist from "../../atoms/Playlist/Playlist.jsx";

const Playlists = () => {
    const {state, dispatch} = useContext(AppContext);
    const currentAudio = useRef(null);

    const handlePlay = (audio) => {
        if (currentAudio.current && currentAudio.current !== audio) {
            currentAudio.current.pause();
        }
        currentAudio.current = audio;
    };

    return (<div className={styles.page}>
            <div className={styles.page_content}>
                <p className={styles.topline}>Rms Sound Station</p>

                <div className={styles.page_playlists}>
                    <Playlist id={"stressfrei"} name={"Stressfrei"} description={"Dein akustischer Ruhepool"} onPlay={handlePlay}/>
                    <Playlist id={"traeumen"} name={"Tagträume"} description={"Musik für Kopf und Herz"} onPlay={handlePlay}/>
                    <Playlist id={"natur"} name={"Natur pur"} description={"Eintauchen in grüne Welten"} onPlay={handlePlay}/>
                    <Playlist id={"energizer"} name={"Energize"} description={"Musik, die dich aufweckt"} onPlay={handlePlay}/>
                </div>

                <div className={styles.quiz_link}>
                    <div className={styles.caption}>
                        <p>RMS Sound Quiz</p>
                        <p>Bist du der Song Lyrics Master?</p>
                    </div>
                    <div className={styles.link}>
                        <Button onClick={() => {
                            dispatch({type: "OPEN_QUIZ"});
                        }}>
                            Jetzt starten
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Playlists;