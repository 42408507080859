import Popup from "../../atoms/Popup/Popup.jsx";
import Headline2 from "../../atoms/Headline2/Headline2.jsx";

import Headline3 from "../../atoms/Headline3/Headline3.jsx";
import Paragraph from "../../atoms/Paragraph/Paragraph.jsx";

const ConditionsPopup = ({show, onClose}) => (
  <Popup show={show} onClose={onClose}>
    <Headline2>Teilnahmebedingungen</Headline2>
    <Headline3>1. Veranstalter/Registrierung/Teilnahme</Headline3>
    <Paragraph>Veranstalter des Gewinnspiels ist die RMS Radio Marketing Service GmbH & Co. KG, Barmbeker Str. 4, 22303
      Hamburg.</Paragraph>
    <Paragraph>Für die Teilnahme am Gewinnspiel sind Vor- und Nachname und eine E-Mail-Adresse sowie das Unternehmen
      anzugeben. Die Verantwortung für Änderungen dieser Daten, insbesondere der E-Mail-Adresse, liegt beim
      Teilnehmer.</Paragraph>
    <Headline3>2. Teilnahmeberechtigte</Headline3>
    <Paragraph>Teilnahmeberechtigt sind natürliche Personen und das 18. Lebensjahr vollendet haben. Die Teilnahme ist
      nicht auf Kunden von RMS beschränkt und nicht vom Erwerb einer Ware oder Dienstleistung abhängig. Nicht
      teilnahmeberechtigt am Gewinnspiel sind alle an der Konzeption und Umsetzung des Gewinnspiels beteiligten Personen
      und Mitarbeiter des Veranstalters sowie verbundener Unternehmen sowie die Angehörigen solcher Mitarbeiter und
      deren Verwandte.</Paragraph>
    <Headline3>3. Sonstige Bedingungen der Teilnahme</Headline3>
    <Paragraph>Eine Teilnahme ist nur im Rahmen des OWM Summit 2024 (am 05. und 06. November 2024) möglich. Die Teilnahme
      hängt in keiner Weise von dem Erwerb von Leistungen von RMS oder von anderen mit der Veranstaltung des
      Gewinnspiels befassten Unternehmen sowie der Einwilligung und den Erhalt eines Newsletters ab. Durch die Teilnahme
      am Gewinnspiel erklärt der Teilnehmer sein Einverständnis mit den Regeln des Gewinnspiels.</Paragraph>
    <Headline3>4. Gewinne, Gewinnbenachrichtigung und Übermittlung des Gewinns</Headline3>
    <Paragraph>Unter allen teilnahmeberechtigten Teilnehmern, die mindestens zwei von drei Titeln richtig erraten haben,
      verlosen wir folgende Gewinne:<br/>
      - Gewinn 1: 1 x SONOS Kopfhörer im Wert von ca. 420 Euro<br/>
      - Gewinn 2: 1 x AirPods Pro im Wert von ca. 250 Euro<br/>
      - Gewinn 3: DAB+ Radio im Wert von ca. 150 Euro
    </Paragraph>
    <Paragraph>Die Ermittlung der Gewinner erfolgt innerhalb von zwei Wochen nach Teilnahmeschluss im Rahmen einer auf dem
      Zufallsprinzip beruhenden Verlosung unter allen Teilnehmern, die 2 von 3 Antworten korrekt beantwortet haben. Die
      Teilnehmer, die Gewinner der Verlosung sind, werden zeitnah über eine gesonderte E-Mail über den Gewinn
      informiert. Die Aushändigung des Gewinns erfolgt ausschließlich an den Teilnehmer, der gewonnen hat. Ein Umtausch,
      eine Übertragung, eine Selbstabholung sowie eine Barauszahlung des Gewinns sind nicht möglich. Eventuell für den
      Versand der Gewinne anfallende Kosten übernimmt der Veranstalter.</Paragraph>
    <Paragraph>Mit Übergabe des Gewinns an eine Transportperson geht die Gefahr auf den Teilnehmer über. Für
      Lieferschäden ist der Veranstalter nicht verantwortlich. Mit der Inanspruchnahme des Gewinns verbundene
      Zusatzkosten gehen zu Lasten des Teilnehmers, der gewonnen hat. Für eine etwaige Versteuerung des Gewinns ist der
      Teilnehmer, der gewonnen hat, selbst verantwortlich. Meldet sich der Teilnehmer, der gewonnen hat, nach zweifacher
      Aufforderung innerhalb einer Frist von 3 Wochen nicht, erlischt der Gewinnanspruch. Der Veranstalter ist in diesem
      Fall berechtigt, eine Ersatzauslosung durchzuführen und den Gewinn dann auf einen anderen Teilnehmer zu
      übertragen. Je Teilnehmer ist nur ein Gewinn möglich. Der Rechtsweg ist ausgeschlossen.</Paragraph>
    <Paragraph>Bei Fragen wende dich bitte an: marketing@rms.de</Paragraph>
  </Popup>
);

export default ConditionsPopup;