import classnames from 'classnames';
import {Form, useForm} from "react-hook-form";
import styles from './ApplicationForm.module.scss';

import Button from '../../atoms/Button/Button.jsx';
import {useContext, useState} from "react";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";


export const base_url = "";

const ApplicationForm = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors}
  } = useForm();

  const [sent, setSent] = useState(false);

  const onSubmit = (data) => {
    // Send to backend
    fetch(`${base_url}/api/save`, {})
      .then(response => response.json())
      .then(data => {
        // console.log("Saved data to server", data);
        setSent(true);
      })
  }

  const {dispatch} = useContext(AppContext);

  if (sent) {
    return (
      <>
        <h2>Vielen Dank für Deine Teilnahme!</h2>
        <p>Wir drücken Dir die Daumen und wünschen Dir viel Glück!</p>
      </>
    )
  }

  return (
    <>
      <h2>Teilnahme am Gewinnspiel</h2>
      <p>Füll die Felder aus und nimm am Gewinnspiel teil.</p>
      <Form
        className={styles.form}
        action={`${base_url}/api/save`}
        control={control}
        onSuccess={() => {
          setSent(true)
        }}
      >
        <div className={classnames(styles.field, styles.fieldHalf)}>
          <label htmlFor="firstname">Vorname*</label>
          <input type="text" id="firstname" name="firstname" {...register('firstname', {required: true})}/>
          {errors.firstname && <span className={styles.error}>Dies ist ein Pflichtfeld</span>}
        </div>
        <div className={classnames(styles.field, styles.fieldHalf)}>
          <label htmlFor="lastname">Nachname*</label>
          <input type="text" id="lastname" name="lastname" {...register('lastname', {required: true})}/>
          {errors.lastname && <span className={styles.error}>Dies ist ein Pflichtfeld</span>}
        </div>
        <div className={classnames(styles.field, styles.fieldHalf)}>
          <label htmlFor="company">Firma*</label>
          <input type="text" id="company" name="company" {...register('company', {required: true})}/>
          {errors.company && <span className={styles.error}>Dies ist ein Pflichtfeld</span>}
        </div>
        <div className={classnames(styles.field, styles.fieldHalf)}>
          <label htmlFor="position">Position*</label>
          <input type="text" id="position" name="position" {...register('position', {required: true})}/>
          {errors.position && <span className={styles.error}>Dies ist ein Pflichtfeld</span>}
        </div>
        <div className={styles.field}>
          <label htmlFor="email">E-Mail*</label>
          <input type="email" id="email" name="email" {...register('email', {required: true})}/>
          {errors.email && <span className={styles.error}>Dies ist ein Pflichtfeld</span>}
        </div>
        <div className={styles.field}>
          <label htmlFor="plz">PLZ*</label>
          <input type="text" id="plz" name="plz" {...register('plz', {required: true})}/>
          {errors.plz && <span className={styles.error}>Dies ist ein Pflichtfeld</span>}
        </div>
        <div className={styles.field}>
          <input type="checkbox" id="marketing_opt_in"
                 name="marketing_opt_in" {...register('marketing_opt_in', {required: false})}/>
          <label htmlFor="marketing_opt_in">RMS darf mich per E-Mail über Leistungen, Produkte und Aktionen
            informieren.</label>
          <p>Mit dem Klick auf ‚Jetzt teilnehmen‘ erklärst Du Dich mit der individuellen Messung und Auswertung von
            Öffnungsraten und Klickraten in Empfängerprofilen zur Gestaltung künftiger Newsletter einverstanden. Deine
            Einwilligung kannst Du jederzeit widerrufen, zum Beispiel per E-Mail an <a
              href="mailto:datenschutz@rms.de">datenschutz@rms.de</a>. Weitere
            Informationen findest Du in unserer <a href="#" onClick={() => {
              dispatch({type: "TOGGLE_DATAPROTECTION"});
            }}>Datenschutzerklärung</a>.</p>
        </div>
        <p className={styles.field}>Für dieses Gewinnspiel gelten unsere&nbsp;
          <a href="#" onClick={() => {
            dispatch({type: "TOGGLE_CONDITIONS"});
          }}>Teilnahmebedingungen</a>.</p>
        <p className={styles.field}>* Pflichtfeld</p>
        <Button>Jetzt teilnehmen</Button>
      </Form>
    </>
  )
}

export default ApplicationForm;