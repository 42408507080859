import styles from "./AnswerButton.module.scss";
import {motion} from "framer-motion"

const Correct = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.711 21.458">
    <path fill="#56c94e" fillRule="nonzero"
          d="M11.3 14.104 4.307 7.113 0 11.42l9.145 9.145a3.05 3.05 0 0 0 4.308 0L29.711 4.308 25.403 0Z"/>
  </svg>
);

const Wrong = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.696 28.696">
    <g fill="none" fillRule="evenodd">
      <g fill="#b6101d" fillRule="nonzero">
        <g>
          <path
            d="M4.309 0 0 4.309l10.039 10.039L0 24.388l4.309 4.308 10.039-10.039 10.039 10.039 4.309-4.308-10.039-10.04L28.696 4.309 24.387 0 14.348 10.04z"/>
        </g>
      </g>
    </g>
  </svg>
);

const AnswerButton = ({onClick, title, interpret, correct, show, delay}) => {
  const variants = {
    neutral: {},
    correct: {
      borderColor: "#56C94E",
      transition: {delay}

    },
    wrong: {
      borderColor: "#B6101D",
      transition: {delay}
    },
  }

  const iconVariants = {
    neutral: {
      scale: 2,
      opacity: 0,
    },
    correct: {
      scale: 1,
      opacity: 1,
      transition: {delay}
    },
    wrong: {
      scale: 1,
      opacity: 1,
      transition: {delay}
    },
  }

  return (
    <motion.button
      className={styles.button}
      onClick={onClick}
      whileTap={{scale: .95}}
      variants={variants}
      initial="neutral"
      animate={show ? (correct ? "correct" : "wrong") : "neutral"}
    >
      <motion.span
        className={styles.button__box}
        variants={variants}
        // initial="neutral"
        // animate={show ? (correct ? "correct" : "wrong") : "neutral"}
      ></motion.span>
      <motion.span
        className={styles.button__icon}
        variants={iconVariants}
      >
      {correct ? <Correct/> : <Wrong/>}
      </motion.span>
      <p className={styles.button__song}>{title}</p>
      <p className={styles.button__interpret}>{interpret}</p>
    </motion.button>
  );
}

export default AnswerButton;