import AudioPlayer from 'react-h5-audio-player';

import {useContext} from "react";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import styles from "./Start.module.scss";
import backArrow from "./back_arrow.svg";

import Button from "../../atoms/Button/Button";
import Headline1 from "../../atoms/Headline1/Headline1.jsx";
import Paragraph from "../../atoms/Paragraph/Paragraph.jsx";
import RestartButton from "../../atoms/RestartButton/RestartButton.jsx";

const Start = () => {
  const {state, dispatch} = useContext(AppContext);

  return (<div className={styles.page}>
    <div className={styles.page_content}>
      <p className={styles.topline}>Rms Sound Quiz</p>
      <div className={styles.back_arrow}>
        <RestartButton onClick={() => {dispatch({type: "PLAYLISTS"})}}/>
      </div>
      <Headline1>Bist Du der Song Lyrics Master?</Headline1>
      <Paragraph>
        Stelle Dein Musik-Wissen auf die Probe! Beim "Song Lyrics Master" übersetzen wir die größten englischen Hits ins
        Deutsche und unser KI-Spotcreator liest sie Dir vor.
      </Paragraph>
      <Paragraph>
        Deine Aufgabe: Erkenne den Song und wähle die richtige Antwort aus. Drei von fünf richtig? Dann gehörst Du
        zu
        den Glücklichen im exklusiven Gewinner-Pool.
      </Paragraph>
      <Paragraph>
        Bist Du bereit, den Hauptgewinn zu holen?
      </Paragraph>
    </div>
    <div className={styles.page_buttons}>
      <div className={styles.buttonRow}>
        <Button onClick={() => {
          dispatch({type: "START"});
        }}>
          Jetzt starten
        </Button>
      </div>
    </div>{/*
    <div className={styles.page_sidebar}>
      <div className={styles.player}>
        <AudioPlayer
          src="https://rmsde.b-cdn.net/soundquiz_mix.mp3"
          autoPlay
          loop
          showJumpControls={false}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          showFilledVolume={false}
          showFilledProgress={false}
          customProgressBarSection={[]}
          onPlay={
            (e) => {
              // Random between 20s and 2 hours
              e.currentTarget.currentTime = Math.floor(Math.random() * (7200 - 20) + 20);
            }
          }
        />
        <p className={styles.player_title}>RMS Playlist</p>
      </div>
    </div>*/}
  </div>)
    ;
}

export default Start;