import styles from './Playlist.module.scss';
import AudioPlayer from "react-h5-audio-player";
import stressfrei_img from './playlist_stressfrei.png';
import traumen_img from './playlist_traeumen.png';
import natur_img from './playlist_natur.png';
import energie_img from './playlist_energizer.png';

const Playlist = ({id, name, description, onPlay}) => {
  let img;
  switch (id) {
    case "stressfrei":
      img = stressfrei_img;
      break;
    case "traeumen":
      img = traumen_img;
      break;
    case "natur":
      img = natur_img;
      break;
    case "energizer":
      img = energie_img;
      break;
  }

  return (
    <div className={styles.playlist}>
      <div className={styles.playlist_image}>
        <img src={img} alt={name}/>
        <AudioPlayer
          className={styles.playlist_audio}
          src={"https://rmsde.b-cdn.net/" + id + ".m4a"}
          loop
          showJumpControls={false}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          showFilledVolume={false}
          showFilledProgress={false}
          customProgressBarSection={[]}
          onPlay={e => onPlay(e.target)}
        />
      </div>
      <div className={styles.playlist_info}>
        <div className={styles.playlist_info_text}>
          <p className={styles.playlist_name}>{name}</p>
          <p className={styles.playlist_description}>{description}</p>
        </div>
      </div>
    </div>
  );
}

export default Playlist;