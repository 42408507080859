import {useState} from "react";
import {motion} from "framer-motion";

import styles from "./AnswerGroup.module.scss";

import positive from "../../../assets/mp3/positive.mp3";
import negative from "../../../assets/mp3/negative.mp3";
import AnswerButton from "../AnswerButton/AnswerButton.jsx";

const AnswerGroup = ({answers, correctAnswer, soundFile, onContinue}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [hideQuestion, setHideQuestion] = useState(false);
  let delayFactor = 0;
  let delay = 0;
  return (
    <motion.div className={styles.group}
      initial={{opacity: 0}}
      animate={{opacity: hideQuestion ? 0 : 1}}
    >
      { selectedAnswer !== null ? (
        <audio src={selectedAnswer === correctAnswer ? positive : negative} autoPlay/>
      ) : (
        <audio src={soundFile} autoPlay loop/>
      ) }
      {
        answers.map((answer, i) => {
          if (answer.id === correctAnswer) {
            delay = (answers.length - 1) * 0.3;
          } else {
            delay = delayFactor * 0.3;
            delayFactor++;
          }
          return (
            <AnswerButton
              key={answer.id}
              onClick={() => {
                setSelectedAnswer(answer.id);
                setTimeout(() => {
                  setHideQuestion(true);
                  setTimeout(() => {
                    if (onContinue) {
                      onContinue(answer.id);
                    }
                  }, 500);
                }, 2000);
              }}
              title={answer.title}
              interpret={answer.interpret}
              correct={answer.id === correctAnswer}
              show={selectedAnswer !== null}
              delay={delay}
            />
          );
        })
      }
    </motion.div>
  )
};

export default AnswerGroup;