import { motion } from "framer-motion";

import Button from "../Button/Button";
import styles from "./Popup.module.scss";

const Popup = ({children, iframe, show, onClose}) => {
  return (
    <motion.div
      className={styles.popupOverlay}
      initial={{
        opacity: 0,
        pointerEvents: 'none',
      }}
      animate={{
        opacity: show ? 1 : 0,
        pointerEvents: show ? 'all' : 'none',
      }}
      onClick={onClose}
    >
      <div className={styles.popup}>
        <button onClick={onClose} className={styles.close_button}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.696 28.696">
            <g fill="none" fillRule="evenodd" className="page-1">
              <g fillRule="nonzero">
                <g>
                  <path
                    fill="#000"
                    d="M4.309 0 0 4.309l10.039 10.039L0 24.388l4.309 4.308 10.039-10.039 10.039 10.039 4.309-4.308-10.039-10.04L28.696 4.309 24.387 0 14.348 10.04z"/>
                </g>
              </g>
            </g>
          </svg>
        </button>
        {iframe ? (
          <iframe
            src={iframe}
            title="Data Protection"
            className={styles.iframe}
          ></iframe>
        ) : null}
        <div className={styles.popupContent}>
          {children}
        </div>
      </div>
    </motion.div>
  );
}

export default Popup;