import song1 from "../../assets/mp3/song1.mp3";
import song2 from "../../assets/mp3/song2.mp3";
import song3 from "../../assets/mp3/song3.mp3";
import song4 from "../../assets/mp3/song4.mp3";
import song5 from "../../assets/mp3/song5.mp3";
import song6 from "../../assets/mp3/song6.mp3";
import song7 from "../../assets/mp3/song7.mp3";
import song8 from "../../assets/mp3/song8.mp3";
import song9 from "../../assets/mp3/song9.mp3";
import song10 from "../../assets/mp3/song10.mp3";
import song11 from "../../assets/mp3/song11.mp3";
import song12 from "../../assets/mp3/song12.mp3";

const questions = [
  {
    id: 1,
    question: "Was ist Song Nummer 1",
    soundFile: song1,
    answers: [
      {
        id: 1,
        title: "Afrika",
        interpret: "Toto",
      },
      {
        id: 2,
        title: "Rosanna.",
        interpret: "Toto",
      },
      {
        id: 3,
        title: "Hold the Line",
        interpret: "Toto",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 2,
    question: "Was ist Song Nummer 2",
    soundFile: song2,
    answers: [
      {
        id: 1,
        title: "Dance with Somebody",
        interpret: "Mando Diao",
      },
      {
        id: 2,
        title: "I Gotta Feeling.",
        interpret: "The Black Eyed Peas",
      },
      {
        id: 3,
        title: "Shut Up and Dance",
        interpret: "WALK THE MOON",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 3,
    question: "Was ist Song Nummer 3",
    soundFile: song3,
    answers: [
      {
        id: 1,
        title: "Daylight.",
        interpret: "No Angels",
      },
      {
        id: 2,
        title: "Satellite.",
        interpret: "Lena Meyer-Landrut",
      },
      {
        id: 3,
        title: "Something in the Water",
        interpret: "Brook Fraser",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 4,
    question: "Was ist Song Nummer 4",
    soundFile: song4,
    answers: [
      {
        id: 1,
        title: "Flowers",
        interpret: "Miley Cyrus",
      },
      {
        id: 2,
        title: "Shallow",
        interpret: "Lady Gaga & Bradley Cooper",
      },
      {
        id: 3,
        title: "Levitating",
        interpret: "Dua Lipa",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 5,
    question: "Was ist Song Nummer 5",
    soundFile: song5,
    answers: [
      {
        id: 1,
        title: "Happy",
        interpret: "Pharrell Williams",
      },
      {
        id: 2,
        title: "Can't Stop the Feeling!",
        interpret: "Justin Timberlake",
      },
      {
        id: 3,
        title: "Uptown Funk",
        interpret: "Mark Ronson ft. Bruno Mars",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 6,
    question: "Was ist Song Nummer 6",
    soundFile: song6,
    answers: [
      {
        id: 1,
        title: "It's My Life",
        interpret: "Bon Jovi",
      },
      {
        id: 2,
        title: "Livin' on a Prayer",
        interpret: "Bon Jovi",
      },
      {
        id: 3,
        title: "Here I Go Again",
        interpret: "Whitesnake",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 7,
    question: "Was ist Song Nummer 7",
    soundFile: song7,
    answers: [
      {
        id: 1,
        title: "Like a Virgin",
        interpret: "Madonna",
      },
      {
        id: 2,
        title: "Material Girl",
        interpret: "Madonna",
      },
      {
        id: 3,
        title: "Girls Just Want to Have Fun",
        interpret: "Cyndi Lauper",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 8,
    question: "Was ist Song Nummer 8",
    soundFile: song8,
    answers: [
      {
        id: 1,
        title: "My Heart Will Go On",
        interpret: "Céline Dion",
      },
      {
        id: 2,
        title: "I Will Always Love You",
        interpret: "Whitney Houston",
      },
      {
        id: 3,
        title: "All by Myself",
        interpret: "Céline Dion",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 9,
    question: "Was ist Song Nummer 9",
    soundFile: song9,
    answers: [
      {
        id: 1,
        title: "Scatman",
        interpret: "Scatman John",
      },
      {
        id: 2,
        title: "Blue (Da Ba Dee)",
        interpret: "Eiffel 65",
      },
      {
        id: 3,
        title: "Mr. Vain",
        interpret: "Culture Beat",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 10,
    question: "Was ist Song Nummer 10",
    soundFile: song10,
    answers: [
      {
        id: 1,
        title: "Single Ladies",
        interpret: "Beyoncé",
      },
      {
        id: 2,
        title: "Run the World (Girls)",
        interpret: "Beyoncé",
      },
      {
        id: 3,
        title: "Hollaback Girl",
        interpret: "Gwen Stefani",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 11,
    question: "Was ist Song Nummer 11",
    soundFile: song11,
    answers: [
      {
        id: 1,
        title: "Wake Me Up Before You Go-Go",
        interpret: "Wham!",
      },
      {
        id: 2,
        title: "Last Christmas",
        interpret: "Wham!",
      },
      {
        id: 3,
        title: "I'm still standing",
        interpret: "Elton John",
      }
    ],
    correctAnswer: 1
  },
  {
    id: 12,
    question: "Was ist Song Nummer 12",
    soundFile: song12,
    answers: [
      {
        id: 1,
        title: "Wannebe",
        interpret: "Spice Girls",
      },
      {
        id: 2,
        title: "Barbie Girl",
        interpret: "Aqua",
      },
      {
        id: 3,
        title: "...Baby One More Time",
        interpret: "Britney Spears",
      }
    ],
    correctAnswer: 1
  }
];

export default questions;