import {useContext} from "react";
import AppContext from "../../../utilities/AppContext/AppContext.jsx";

import styles from "./Question.module.scss";

import Headline2 from "../../atoms/Headline2/Headline2.jsx";
import AnswerGroup from "../../atoms/AnswerGroup/AnswerGroup.jsx";
import Paragraph from "../../atoms/Paragraph/Paragraph.jsx";
import RestartButton from "../../atoms/RestartButton/RestartButton.jsx";

const Question = () => {
  const {state, dispatch} = useContext(AppContext);

  const {
    questions,
    currentQuestion
  } = state;

  const question = questions[currentQuestion];
  const hasNextQuestion = currentQuestion < questions.length - 1;

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <RestartButton onClick={() => dispatch({type: "RESTART"})}/>
      </div>
      <Headline2>Welcher Song ist das?</Headline2>
      <Paragraph>
        Welcher englische Song versteckt sich hinter diesen deutschen Lyrics? Wähle Deinen Favoriten jetzt aus:
      </Paragraph>
      <AnswerGroup
        soundFile={question.soundFile}
        key={question.id}
        answers={question.answers}
        correctAnswer={question.correctAnswer}
        onContinue={(answer) => {
          dispatch({type: "ANSWER", answer: answer});
          if (hasNextQuestion) {
            dispatch({type: "NEXT_QUESTION"});
          } else {
            dispatch({type: "FINISH"});
          }
        }}
      />
    </div>
  );
}

export default Question;